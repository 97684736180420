import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface VideoState {
  info: Api.Response.VideoTranslate | null
}

const initialState: VideoState = {
  info: null
}

const videoSettingsSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideoInfo: (state, action: PayloadAction<{ info: Api.Response.VideoTranslate }>) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setVideoInfo } = videoSettingsSlice.actions
export default videoSettingsSlice.reducer
