import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetOne, apiUpdate } from '../../api/crud'

interface SettingsState {
  maintenance: boolean | null
  isLoading: boolean
  error: string | null
}

const initialState: SettingsState = {
  maintenance: false,
  isLoading: false,
  error: null
}
export const fetchSiteStatus = createAsyncThunk('settings/fetchSiteStatus', async () => {
  const response = await apiGetOne('maintenance')
  if (response.isError) {
    throw new Error('Не удалось получить статус сайта')
  }
  return response.data
})

export const updateSiteStatus = createAsyncThunk<{ maintenance: boolean }, boolean>(
  'settings/updateSiteStatus',
  async (maintenance: boolean, { rejectWithValue }) => {
    try {
      //Переписать потом apiUpdate на запрос без id
      const response = await apiUpdate('maintenance', 1, { maintenance })
      if (response.isError) {
        return rejectWithValue('Не удалось обновить статус сайта')
      }
      return response.data as { maintenance: boolean }
    } catch (error) {
      return rejectWithValue('Произошла ошибка при обновлении статуса')
    }
  }
)
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<{ maintenance: boolean }>) => {
      return { ...state, ...action.payload }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSiteStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(fetchSiteStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenance = action.payload.maintenance
      })
      .addCase(fetchSiteStatus.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Произошла ошибка'
      })
      .addCase(updateSiteStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateSiteStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenance = action.payload.maintenance
      })
      .addCase(updateSiteStatus.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Произошла ошибка при обновлении статуса'
      })
  }
})

export const { setSettings } = settingsSlice.actions
export default settingsSlice.reducer
