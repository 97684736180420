interface Voice {
  name: string
  sample: string
  value: string
  lang: string
}

export const voices: Voice[] = [
  { name: 'Hanna (жен.)', value: 'f-de-5', lang: 'de', sample: '/assets/sample/Hanna_sample.wav' },
  { name: 'Hans (муж.)', value: 'm-de-6', lang: 'de', sample: '/assets/sample/Hans_sample.wav' },
  { name: 'Allis (жен.)', value: 'f-en-35', lang: 'en', sample: '/assets/sample/Allis_sample.wav' },
  { name: 'Barbara (жен.)', value: 'f-en-37', lang: 'en', sample: '/assets/sample/Barbara_sample.wav' },
  { name: 'Jane (жен.)', value: 'f-en-45', lang: 'en', sample: '/assets/sample/Jane_sample.wav' },
  { name: 'Jim (муж.)', value: 'm-en-46', lang: 'en', sample: '/assets/sample/Jim_sample.wav' },
  { name: 'Joe (муж.)', value: 'm-en-34', lang: 'en', sample: '/assets/sample/Joe_sample.wav' },
  { name: 'John (муж.)', value: 'm-en-43', lang: 'en', sample: '/assets/sample/John_sample.wav' },
  { name: 'July (жен.)', value: 'f-en-44', lang: 'en', sample: '/assets/sample/July_sample.wav' },
  { name: 'Lora (жен.)', value: 'f-en-40', lang: 'en', sample: '/assets/sample/Lora_sample.wav' },
  { name: 'Rachel (жен. дет.)', value: 'f-en-39', lang: 'en', sample: '/assets/sample/Rachel_sample.wav' },
  { name: 'Michael (муж.)', value: 'm-en-41', lang: 'en', sample: '/assets/sample/Michael_sample.wav' },
  { name: 'Robert (муж.)', value: 'm-en-36', lang: 'en', sample: '/assets/sample/Robert_sample.wav' },
  { name: 'Tom (муж.)', value: 'm-en-42', lang: 'en', sample: '/assets/sample/Tom_sample.wav' },
  { name: 'Ana (жен.)', value: 'f-es-1', lang: 'es', sample: '/assets/sample/Ana_sample.wav' },
  { name: 'Antonio (муж.)', value: 'm-es-2', lang: 'es', sample: '/assets/sample/Antonio_sample.wav' },
  { name: 'Pier (муж.)', value: 'm-fr-2', lang: 'fr', sample: '/assets/sample/Pier_sample.wav' },
  { name: 'Sofia (жен.)', value: 'f-fr-1', lang: 'fr', sample: '/assets/sample/Sofia_sample.wav' },
  { name: 'Donald (муж.)', value: 'm-en-30', lang: 'am', sample: '/assets/sample/Donald_sample.wav' },
  { name: 'Henry (муж.)', value: 'm-en-30', lang: 'am', sample: '/assets/sample/Henry_sample.wav' },
  { name: 'Steffany (жен.)', value: 'f-en-29', lang: 'en', sample: '/assets/sample/Steffany_sample.wav' },
  { name: 'Aahan (муж.)', value: 'm-mr-2', lang: 'hi', sample: '/assets/sample/Aahan_sample.wav' },
  { name: 'Aliza (жен.)', value: 'f-mr-1', lang: 'hi', sample: '/assets/sample/Aliza_sample.wav' },
  { name: 'Antonio (муж.)', value: 'm-it-2', lang: 'it', sample: '/assets/sample/Antonion_sample.wav' },
  { name: 'Lili (жен.)', value: 'f-it-3', lang: 'it', sample: '/assets/sample/Lili_sample.wav' },
  { name: 'Akayo (муж.)', value: 'm-ja-1', lang: 'ja', sample: '/assets/sample/Akayo_sample.wav' },
  { name: 'Azumi (жен.)', value: 'f-ja-2', lang: 'ja', sample: '/assets/sample/Azumi_sample.wav' },
  { name: 'Kun-woo (муж.)', value: 'm-ko-2', lang: 'ko', sample: '/assets/sample/Kun-woo_sample.wav' },
  { name: 'Son (жен.)', value: 'f-ko-3', lang: 'ko', sample: '/assets/sample/Son_sample.wav' },
  { name: 'Carolina (жен.)', value: 'f-es-36', lang: 'pt', sample: '/assets/sample/Carolina_sample.wav' },
  { name: 'Luis (муж.)', value: 'f-es-36', lang: 'pt', sample: '/assets/sample/Luis_sample.wav' },
  { name: 'Nadejda (жен.)', value: 'f-ru-2', lang: 'ru', sample: '/assets/sample/Nadejda_sample.wav' },
  { name: 'Sergey (муж.)', value: 'm-ru-1', lang: 'ru', sample: '/assets/sample/Sergey_sample.wav' },
  { name: 'Arslan (муж.)', value: 'm-tr-1', lang: 'tr', sample: '/assets/sample/Arslan_sample.wav' },
  { name: 'Asel (жен.)', value: 'f-tr-2', lang: 'tr', sample: '/assets/sample/Asel_sample.wav' },
  { name: 'Aziza (жен.)', value: 'f-uz-1', lang: 'uz', sample: '/assets/sample/Aziza_sample.wav' },
  { name: 'Aziz (муж.)', value: 'm-uz-2', lang: 'uz', sample: '/assets/sample/Aziz_sample.wav' },
  { name: 'Rayhon (жен.)', value: 'f-uz-1', lang: 'uz', sample: '/assets/sample/Rayhon_sample.wav' },
  { name: 'ChangYan (муж.)', value: 'm-zh-6', lang: 'zh', sample: '/assets/sample/ChangYan_sample.wav' },
  { name: 'QinJiao (жен.)', value: 'f-zh-14', lang: 'zh', sample: '/assets/sample/QinJiao_sample.wav' }
]
