import { Button, Card, Flex, Typography } from 'antd'
import error from '../../../assets/error.png'
import { useNavigate } from 'react-router-dom'

const ErrorVideo = ({ changeStatus }: { changeStatus: (status: number) => void }) => {
  const nav = useNavigate()

  return (
    <Flex align='center' justify='center' gap={20} style={{ paddingTop: '40px' }}>
      <Card title='Обработка видео завершилось с ошибкой' style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <img src={error} alt='Ошибка обработки видео' height={'200px'} style={{ margin: '15px' }} />
          <Typography.Title level={4}>Ошибка обработки видео</Typography.Title>
          <Typography.Paragraph>
            Пожалуйста попробуйте снова или свяжитесь с нами в <a href='https://t.me/Uberwow'>Telegram</a>
          </Typography.Paragraph>
          <Flex align='center' justify='center' gap={10}>
            <Button type='primary' onClick={() => changeStatus(1)}>
              Перезапустить перевод
            </Button>
            <Button type='default' onClick={() => nav('/app/list')}>
              Мои видео
            </Button>
          </Flex>
        </div>
      </Card>
    </Flex>
  )
}
export default ErrorVideo
