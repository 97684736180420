import React from 'react'
import { Card } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const AddVideo = () => {
  return (
    <Link to='/app/add'>
      <Card
        hoverable
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <PlusOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
        <div style={{ marginTop: '16px' }}>Добавить новое видео</div>
      </Card>
    </Link>
  )
}

export default AddVideo
