export const languageOptions = [
  {
    value: 'en',
    label: 'Английский',
    icon: '/assets/icons/flags/GB.svg'
  },
  {
    value: 'am',
    label: 'Американский',
    icon: '/assets/icons/flags/AM.svg'
  },
  {
    value: 'de',
    label: 'Немецкий',
    icon: '/assets/icons/flags/DE.svg'
  },
  {
    value: 'hi',
    label: 'Индийский',
    icon: '/assets/icons/flags/HI.svg'
  },
  {
    value: 'es',
    label: 'Испанский',
    icon: '/assets/icons/flags/ES.svg'
  },
  {
    value: 'it',
    label: 'Итальянский',
    icon: '/assets/icons/flags/IT.svg'
  },
  {
    value: 'zh',
    label: 'Китайский',
    icon: '/assets/icons/flags/CN.svg'
  },
  {
    value: 'ko',
    label: 'Корейский',
    icon: '/assets/icons/flags/KO.svg'
  },
  {
    value: 'pt',
    label: 'Португальский',
    icon: '/assets/icons/flags/PT.svg'
  },
  {
    value: 'ru',
    label: 'Русский',
    icon: '/assets/icons/flags/RU.svg'
  },
  {
    value: 'tr',
    label: 'Турецкий',
    icon: '/assets/icons/flags/TR.svg'
  },
  {
    value: 'uz',
    label: 'Узбекский',
    icon: '/assets/icons/flags/UZ.svg'
  },
  {
    value: 'fr',
    label: 'Французский',
    icon: '/assets/icons/flags/FR.svg'
  },
  {
    value: 'ja',
    label: 'Японский',
    icon: '/assets/icons/flags/JP.svg'
  }
]
