import { useState, useEffect } from 'react'
import { CoreForm } from '../../components/CoreForm'
import { Button, Col, Flex, message, Row, Spin, Switch } from 'antd'
import { apiGetList, apiCount, apiGetListWithParams } from '../../api/crud'
import Video from './components/Video'
import { Pagination } from 'antd'
import { Link } from 'react-router-dom'
import { FormatField } from '../../components/FormatField'
import { FormatFormInput } from '../../components/FormatFormInput'
import { useAppSelector } from '../../hooks/useAppSelector'
import AddVideo from './components/AddVideo'

const pageSize = 7
const User = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const [data, setData] = useState<Api.Response.VideoTranslate[]>()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(10)
  const [adminVideoOnly, setAdminVideoOnly] = useState<boolean>(true)
  const [updateList, setUpdateList] = useState<number>(0)
  const url = 'translate'

  const extraAdminBar = () => {
    return (
      <Switch
        checkedChildren='Мои'
        unCheckedChildren='Все'
        defaultChecked
        value={adminVideoOnly}
        onChange={() => {
          setAdminVideoOnly(!adminVideoOnly)
          setPage(1)
        }}
      />
    )
  }
  const updateListTrigger = () => {
    setUpdateList(updateList + 1)
  }
  const getData = async (params: Api.Params.Pagination) => {
    setLoading(true)
    const result = await apiGetListWithParams(url, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      setLoading(false)
      return
    }
    setLoading(false)
    return result.data
  }
  const getTotal = async () => {
    const result = await apiCount(`translate`, adminVideoOnly)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    setTotal(result.data)
  }

  useEffect(() => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      my: adminVideoOnly
    }
    getData(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, total, adminVideoOnly, updateList])

  useEffect(() => {
    getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateList, adminVideoOnly])

  return (
    <>
      {contextHolder}
      <CoreForm title='Мои ролики' extra={user.role === 'admin' ? extraAdminBar() : ''}>
        {data && data.length === 0 && (
          <div style={{ textAlign: 'center', margin: '32px 0' }}>
            <p>
              <b>Роликов пока нет =( </b>
              <br /> Вы можете пополнить баланс и добавить первое видео или попробовать бесплатную версию
            </p>
            <Flex gap={15} justify='center'>
              <Link to='/app/add'>
                <Button type='primary'>Добавить новый</Button>
              </Link>
              <Link to='/app/balance'>
                <Button type='default'>Пополнить баланс</Button>
              </Link>
            </Flex>
          </div>
        )}
        <Row gutter={16}>
          {loading ? (
            <Spin style={{ margin: '32px auto' }} />
          ) : (
            <>
              {data?.map(item => (
                <Col span={6} key={item.id} style={{ marginBottom: '15px' }}>
                  <Video item={item} updateListTrigger={updateListTrigger} />
                </Col>
              ))}
              <Col span={6} key={new Date().getTime()} style={{ marginBottom: '15px' }}>
                <AddVideo />
              </Col>
            </>
          )}
        </Row>
        <Row style={{ justifyContent: 'center', marginTop: 32 }}>
          <Pagination
            defaultCurrent={page}
            total={total}
            pageSize={pageSize}
            onChange={values => setPage(values)}
            hideOnSinglePage
          />
        </Row>
      </CoreForm>
    </>
  )
}

export default User
