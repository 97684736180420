import { Button, Flex } from 'antd'
import { CoreForm } from '../../components/CoreForm'
import { FormatField } from '../../components/FormatField'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { fetchSiteStatus, updateSiteStatus } from '../../store/slices/settings'

const Statistics = () => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const { maintenance, isLoading } = useAppSelector(state => state.settings)

  useEffect(() => {
    dispatch(fetchSiteStatus())
  }, [dispatch])

  const handleToggleMaintenance = () => {
    dispatch(updateSiteStatus(!maintenance))
  }

  return (
    <CoreForm
      title={
        <Flex>
          <FormatField value={!maintenance ? 'Активен' : undefined} type='activeTag' />
        </Flex>
      }
      extra={
        <Flex justify='end' gap={8}>
          <Button type='link' onClick={() => nav(-1)}>
            Назад
          </Button>
          <Button type='primary' onClick={handleToggleMaintenance} loading={isLoading}>
            {maintenance ? 'Выключить' : 'Включить'} режим обслуживания
          </Button>
        </Flex>
      }
    ></CoreForm>
  )
}

export default Statistics
