import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/user'
import videoSettingsReducer from './slices/video'
import settingsReducer, { fetchSiteStatus } from './slices/settings'

export const store = configureStore({
  reducer: {
    user: userReducer,
    video: videoSettingsReducer,
    settings: settingsReducer
  }
})

//Получаем статус сайта при инициализации приложения
store.dispatch(fetchSiteStatus())

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
