import React from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { Button, Result } from 'antd'

interface MaintenanceWrapperProps {
  children: React.ReactNode
}

const MaintenanceWrapper: React.FC<MaintenanceWrapperProps> = ({ children }) => {
  const { maintenance } = useAppSelector(state => state.settings)

  if (maintenance) {
    return (
      <Result
        status='500'
        title='Техническое обслуживание'
        subTitle='Сайт на техническом обслуживании. Пожалуйста, зайдите позже.'
        extra={
          <Button type='primary' onClick={() => window.location.reload()}>
            Обновить страницу
          </Button>
        }
      />
    )
  }

  return <>{children}</>
}

export default MaintenanceWrapper
